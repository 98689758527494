import React from "react";
import MAP_IMAGE from "../../assets/images/maps.png";
import { Link, useNavigate } from "react-router-dom";

const PackageCard = ({ tour }) => {
  const navigate = useNavigate();

  const handlePackageNavigate = (tour) => {
    navigate(`/package/${tour?.TourURL}/`, {
      state: { tourData: tour },
    });
  };

  return (
    <div className="property-hotel-items">
      <div className="property-hotel-pic">
        <img src={tour?.TourImage} />
      </div>
      <div className="property-hotel-data">
        <div className="property-hotel-first">
          <div className="property-hotel-data-midd">
            <div className="days-desgin">
              <span>{`${tour?.NoOfDays} Days`}</span>{" "}
              <strong>{`${tour?.NoOfNights} Nights`}</strong>
            </div>
            <h4
              className="hoverable-title"
              onClick={() => {
                handlePackageNavigate(tour);
              }}
            >
              {tour?.TourName}
            </h4>
            <div
              dangerouslySetInnerHTML={{
                __html: tour?.ShortDescription,
              }}
            />

            <ul className="property-opti">
              {tour?.toufacility?.map((facility, index) => (
                <li key={index}>
                  <img src={facility?.Image} />
                  <span>{facility?.Name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="property-hotel-data-right">
            <span className="pricestaring">
              {tour?.RoomType} <br />
              <strong>₹{tour?.Rate}</strong>
            </span>
            <p>{tour?.Ratetype}</p>
            <div className="see-availbtn">
              <button
                onClick={() => handlePackageNavigate(tour)}
                className="btn btn-fill round"
              >
                View Details
              </button>
              <Link className="btn btn-fill round btn-outline">
                Enquire Now
              </Link>
            </div>
            {/* <div className="share-wishlist">
              <a href="#">
                <img src={SHARE_ICON} />
              </a>{" "}
              |
              <a href="#">
                <img src={WHITELIST} />
              </a>
            </div> */}
          </div>
        </div>
        <div className="property-hotel-data-sec">
          <img className="map-loc" src={MAP_IMAGE} />
          <p>
            <span>{tour?.TourCities}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
