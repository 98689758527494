import React from "react";
import { useDispatch } from "react-redux";
import { openDialog } from "../../pages/home/homeSlice";

const MobileWhatsappandInquiryBtn = () => {
  const dispatch = useDispatch();
  return (
    <ul class="mobile-footer-btns">
      <li className="mobile-inquiry-btn" onClick={() => dispatch(openDialog())}>
        <a href="javascript:void(0)">
          <i class="fa fa-file-text-o"></i>
          <span>Send Inquiry</span>
        </a>
      </li>
      <li className="mobile-whatsapp-btn">
        <a href="https://wa.me/917567096999/" target="_blank">
          <i class="fa fa-whatsapp"></i>
          <span>Whatsapp</span>
        </a>
      </li>
    </ul>
  );
};

export default MobileWhatsappandInquiryBtn;
