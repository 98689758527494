import React from "react";
import { openDialog } from "../../pages/home/homeSlice";
import { useDispatch } from "react-redux";

const WhatsappandInquiryBtn = () => {
  const dispatch = useDispatch();
  return (
    <ul class="whatsapp-inquiry-btns">
      <li className="inquiry-button" onClick={() => dispatch(openDialog())}>
        <a href="javascript:void(0)">
          <i class="fa fa-file-text-o"></i>
          <span>Send Inquiry</span>
        </a>
      </li>
      <li className="whatsapp-button">
        <a href="https://wa.me/917567096999/" target="_blank">
          <i class="fa fa-whatsapp"></i>
          <span>Whatsapp</span>
        </a>
      </li>
    </ul>
  );
};

export default WhatsappandInquiryBtn;
